/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
const initialState = {
	authRes: false,
	languageParam: "",
	arabicLanguageParam:false,
	authenticationResponse: {},
	getAllEnLabels: [],
	authToken: "",
	resetPasswordResponse: [],
	esmaBarResponseData: [],
	getUserLocation: "",
	expiredLinkValidationRes: {},
	forgotUserRes: {},
	esmaText: "",
	transliterationRules: [],
	forgotPass: {},
	autoLoginResponse: {},
	userEntity: "",
	authError: {},
	rbName: '',
	rb_platformOptions: [],
	rb_defaultPlatform: '',
	rb_denominationOptions: [],
	liveChatStatus: "",
	liveAgentID:"",
	germanSupportLang: false,
	languageChange: false,
	allDependencyCheck: false,
	loginRes: { data: { "user-details": { platform: "" } } }
};
const signUpSlice = createSlice({
	name: "signUp",
	initialState,
	reducers: {
		autentication: (state, action) => {
			state.signUpRes = action.payload;
			state.authRes = action.payload.success || action.payload.authRes;
			state.authenticationResponse = action.payload.data;
			state.authToken = action.payload.data
				? action.payload.data["login-response"].token
				: action.payload.authToken;

			state.authError = {};
			state.rb_platformOptions =  action.payload.data ? action.payload.data["signup-response"].users[0].rbPlatformOption : action.payload.rb_platformOptions;
			state.rb_defaultPlatform =  action.payload.data ? action.payload.data["signup-response"].users[0].rbDefaultPlatform : action.payload.rb_defaultPlatform;
			state.rb_denominationOptions =  action.payload.data ? action.payload.data["signup-response"].users[0].rbDenominationOptions : action.payload.rb_denominationOptions;
		},
		authenticationError: (state, action) => {
			state.authError = action.payload;
		},
		languagequeryParam: (state, action) => {
			state.languageParam = action.payload;
		},
		arabicLanguagequeryParam: (state, action) => {
			state.arabicLanguageParam = action.payload;
		},
		
		allDependencyCheckParam: (state, action) => {
			state.allDependencyCheck = action.payload;
		},
		germanSupport: (state, action) =>{
			state.germanSupportLang = action.payload;		
		},
		setUserEntity: (state, action) => {
			state.userEntity = action.payload;
		},
		updatePlatformValue: (state, action) => {
			if(state?.loginRes?.data?.["user-details"]) {
				state.loginRes.data["user-details"].platform = action.payload;
			}
		},
		loginResponse: (state, action) => {
			state.loginRes = action.payload;
			state.authRes = action.payload.success;
			state.authToken = action.payload?.data
				? action.payload?.data["login-response"]?.token
				: action.payload?.authToken ? action.payload?.authToken : "";

			state.userEntity = action.payload.data
				? action.payload.data["user-details"].entity
				: "";
			state.rbName = action.payload.data
				? action.payload.data["user-details"].rbName
				: "";
				state.rb_platformOptions =  action.payload.data ? action.payload.data["user-details"].rbPlatformOption : action.payload.rb_platformOptions;
			state.rb_defaultPlatform = action.payload.data
				? action.payload.data["user-details"].rbDefaultPlatform
				: action.payload.rb_defaultPlatform;
			state.rb_denominationOptions =  action.payload.data ? action.payload.data["user-details"].rbDenominationOptions : action.payload.rb_denominationOptions;
			state.languageParam = action.payload?.data && action.payload?.data["user-details"] && action.payload.data["user-details"].userLocale;
		},
		setAllEnLabels: (state, action) => {
			state.getAllEnLabels = action.payload;
		},
		forgotUsernameResponse: (state, action) => {
			state.forgotUserRes = action.payload;
		},
		forgotPasswordResponse: (state, action) => {
			state.forgotPass = action.payload;
		},
		resetPasswordRes: (state, action) => {
			state.resetPasswordResponse = action.payload;
		},
		removeForgotUsername: (state, action) => {
			state.forgotUserRes = {};
		},
		removeForgotPass: (state, action) => {
			state.forgotUserRes = {};
			state.resetPasswordResponse = {};
			state.expiredLinkValidationRes = {};
			state.forgotPass = {};
		},
		additionalProperties: (state, action) => {
			state.loginRes = {};
		},
		setCountryListInState: (state, action) => {
			state.countryList = action.payload;
		},
		esmaBarResponse: (state, action) => {
			state.esmaBarResponseData = action.payload;
		},
		getUserCurrentLocation: (state, action) => {
			state.getUserLocation = action.payload;
		},
		linkExpiredValidationResponse: (state, action) => {
			state.expiredLinkValidationRes = action.payload;
		},
		storeEsmaText: (state, action) => {
			state.esmaText = action.payload;
		},
		setTransliterationTable: (state, action) => {
			state.transliterationRules = action.payload;
		},
		autoLoginResponseData: (state, action) => {
			let autoLoginMessage = action?.payload?.data["login-response"]?.message;
			if (autoLoginMessage === "Login Success") {
				state.autoLoginResponse = action.payload;
				state.loginRes = action.payload;
				state.authRes = action.payload.success;
				state.authToken = action.payload.data
					? action.payload.data["login-response"].token
					: "";
				state.userEntity = action.payload.data
					? action.payload.data["user-details"].entity
					: "";
				state.rbName = action.payload.data
					? action.payload.data["user-details"].rbName
					: "";
				state.rb_platformOptions = action.payload.data ? action.payload.data["user-details"].rbPlatformOption : [];
				state.rb_defaultPlatform = action.payload.data
					? action.payload.data["user-details"].rbDefaultPlatform
					: "";
				state.languageParam = action.payload.data
					? action.payload.data["user-details"].userLocale
					: "";
				state.rb_denominationOptions = action.payload.data ? action.payload.data["user-details"].rbDenominationOptions : [];
			} else {
				let autoResponse = JSON.parse(JSON.stringify(action.payload));
				autoResponse.data["login-response"].email = autoResponse?.data["user-details"]?.email
				delete autoResponse.data["user-details"]
				state.autoLoginResponse = autoResponse;
			}

		},
		clearAuthResp: (state, action) => {
			state.authRes = false;
			state.authError = {}
			state.rbName = ""
		},
		setReferingBroker: (state, action) => {
			state.rbName = action.payload;
		},
		liveChat: (state,action)=>{
            state.liveChatStatus = action.payload;
        },
		liveChatID: (state,action)=>{
            state.liveAgentID = action.payload;
        },
		languageChangeSlice: (state, action) =>{
			state.languageChange = action.payload;
		},
		clearResults() {},
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
			return signUpSlice.getInitialState;
		});
	},
});
// Storing the query param of language for login page
export const selectedLanguage = (lang) => async (dispatch) => {
	dispatch(languagequeryParam(lang));
};
export const storeEsmaCompleteText = (text) => async (dispatch) => {
	dispatch(storeEsmaText(text));
};
export const clearAuthResponse = () => async (dispatch) => {
	dispatch(clearAuthResp());
};
export const setUserCurrentLocation = (location) => async (dispatch) => {
	dispatch(getUserCurrentLocation(location));
};
export const {
	autentication,
	languagequeryParam,
	arabicLanguagequeryParam,
	loginResponse,
	setAllEnLabels,
	forgotPasswordResponse,
	removeForgotPass,
	clearResults,
	resetPasswordRes,
	setCountryListInState,
	setUserEntity,
	esmaBarResponse,
	getUserCurrentLocation,
	linkExpiredValidationResponse,
	forgotUsernameResponse,
	storeEsmaText,
	setTransliterationTable,
	autoLoginResponseData,
	clearAuthResp,
	additionalProperties,
	authenticationError,
	setReferingBroker,
	removeForgotUsername,
	liveChat,
	liveChatID,
	germanSupport,
	languageChangeSlice,
	allDependencyCheckParam,
	updatePlatformValue
} = signUpSlice.actions;
export default signUpSlice.reducer;
