import { Suspense, useState, useEffect, useMemo } from "react";
import LocaleContext from "./context/LocaleContext";
import Routes from "./routes";
import i18n from "./utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import {
	setAllEnLabels,
	setUserCurrentLocation,
	setUserEntity,
	setCountryListInState,
	removeForgotPass,
	languagequeryParam,
	loginResponse,
	autentication
} from "./reducers/signUpSlice";
import { configurationServices } from "./constants/configurationServices";
import Loader from "./components/Loader";
import axios from "axios";
import { ipAddressSave, currentCountrySave } from "./services/ipAddress.service";
import { store } from "./store";
import { getUserDetails } from "./utils/getUsersDetails";
import { updateUserInfo } from "./reducers/userInfoSlice";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { countryList, localeList } from "./reducers/commonSlice";
import { getGeoLocationEndpoint } from "./utils/endPointMapper";
import Cookies from 'js-cookie';
import "./App.scss";

const geolocationAPI = getGeoLocationEndpoint();

/* istanbul ignore next */
function App() {
	const dispatch = useDispatch();
	const { userEntity, languageParam } = useSelector((state) => state.signUp);
	const { currentCountry } = useSelector((state) => state.ipAddress);
	const { user } = useSelector((state) => state.user);
	const state = store.getState();
	const [countriesList, setCountryList] = useState();
	const [locale, setLocale] = useState(languageParam? languageParam : "en-US");

	const languageVal = languageParam? languageParam : locale;
	const memoizedContextValue = useMemo(()=>({locale, languageVal, setLocale}), [locale, languageVal])

	const loadDynamicScript = () => {
		if(typeof window.__fxcmGtm === 'undefined' && document.getElementById('gtm') === null){
			const useSelfHostedGA = process.env.REACT_APP_SELF_HOSTED_GA;
			const gtmKey = process.env.REACT_APP_ANALYTICS_MEASUREMENT_ID;
			const url =
				useSelfHostedGA && useSelfHostedGA === "true"
					? window.location.origin + "/tagmanager"
					: "https://www.googletagmanager.com";
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.setAttribute('id','gtm');
			script.textContent = `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'${url}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${gtmKey}');
				`;
			document.head.appendChild(script);
		}	
	};

	loadDynamicScript();

	/*
	To reset to initial state and logout if the user refreshes or 
	if user is ideal and session timeout happens
	*/
	useEffect(() => {
		if (Cookies?.get('token')) fetchUserDetails();
		dispatch(removeForgotPass());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchUserDetails = async () => {
		const response = await getUserDetails(Cookies?.get("token"), "refresh");
		const responseData = response?.data?.["user-details"];
		let accRejectionStatus = "SUCCESS";
		if (responseData?.userForms?.currentStep === "ONBOARDING_COMPLETE") {
			if (responseData?.userForms?.accountRejectionReason)
				accRejectionStatus = responseData?.userForms?.accountRejectionReason
		}
		const payLoadData ={
			...responseData?.userForms,
			fxowsAccountNumber:responseData?.fxowsAccountNumber,
			email: responseData?.email,
			userName: responseData?.username,
			countryOfResidence: responseData?.countryOfResidenceCode,
			locale: responseData?.userLocale,
			authToken: Cookies?.get("token"),
			currentStep: responseData?.userForms.currentStep || "SIGN_UP",
			accountRejectionReason: accRejectionStatus,
			hrinTrigger: responseData?.userForms?.hrinTrigger ? true : null,
			applicationStatus: responseData?.userForms?.applicationStatus,
			crsStatus: responseData?.userForms?.crsStatus,
			successPage: responseData?.userForms?.currentStep === "ONBOARDING_COMPLETE" ? true : false
		}
		let signUpPayload = {}
		if(responseData.rbName) {
			signUpPayload = {
				authRes: true,
				authToken: Cookies?.get("token"),
				rb_platformOptions: responseData?.rbPlatformOption ? responseData?.rbPlatformOption : [],
			    rb_defaultPlatform : responseData?.rbDefaultPlatform ? responseData?.rbDefaultPlatform : '',
			    rb_denominationOptions: responseData?.rbDenominationOptions ?responseData?.rbDenominationOptions : []
			}
		} else {
		signUpPayload = {
			// ...data12.data?.["user-details"],
			authRes: true,
			authToken: Cookies?.get("token")
		}
	}
		const responsePayload = {
			...response,
			authRes: response?.success
		}
		dispatch(setUserEntity(responseData?.entity));
		dispatch(updateUserInfo(payLoadData));
		dispatch(loginResponse(responsePayload));
		dispatch(autentication(signUpPayload));
	};

	const fetchEnlabelsJson = () => {
		fetch(configurationServices(userEntity).en_labelsList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(setAllEnLabels(data));
			})
			.catch((e) => {
				console.log(e.message);
			});
	};

	const fetchCountryLocale = () =>{
		fetch(configurationServices(userEntity, languageParam).labelsList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				dispatch(localeList(data))
			})
			.catch((e) => {
				console.log(e.message);
			});
	};

	useEffect(()=>{
		if(!languageParam){
			fetchCountryLocale();
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[languageParam])

	const isEmptyLocation = Object.values(state.ipAddress).includes(null);
	useEffect(() => {
		if(isEmptyLocation ){
			axios.get(geolocationAPI).then((res) => {
				if(countriesList){
				const geoCountry = res.data.country.toUpperCase()
				const currentCOR = countriesList?.find((item) => item.country_desc === geoCountry);
				dispatch(currentCountrySave(currentCOR.country_code));
	
				}
				const uniqueIp = Array.from(new Set(res.data.ipAddress.split(",")))[0];
				dispatch(ipAddressSave(uniqueIp));
			});
		}		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countriesList]);

	const getUserCountry = () => {
		dispatch(setUserCurrentLocation(currentCountry));
		localStorage.setItem("userCurrentLocation", currentCountry );
		return currentCountry;
	};

	const userCurrentLocation = localStorage.getItem("userCurrentLocation");

	useEffect(() => {
		fetchEnlabelsJson();
		if (user?.authToken === "" && currentCountry) {
			getUserCountry();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCountry]);

	const fetchCountryJson = () => {
		let countriesList = configurationServices(userEntity).countryList;
		fetch(countriesList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let location = currentCountry;
				let filterSelCountry = data.filter(
					(item) => item.country_code === location
				);
				if (location)
					dispatch(setUserEntity(filterSelCountry[0]?.entity_code));
				setCountryList(data);
				dispatch(countryList(data))
			})
			.catch((e) => {
				console.log(e.message);
			});
	};

	useEffect(() => {
		if (!state.signUp.authRes) {
			fetchCountryJson();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userCurrentLocation]);

	useEffect(() => {
		dispatch(setCountryListInState(countriesList));
		window.localStorage.setItem('domainUrl',window?.location?.host);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
	// const navigationEntries = performance.getEntriesByType('navigation');
    // if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
    //   localStorage.removeItem('i18nextLng'); // Clear only on refresh
    // }

		i18n.on("languageChanged", (lng) => {   
		setLocale(lng);
		dispatch(languagequeryParam(lng));
	});
	return () => {
		i18n.off("languageChanged");
	  }; 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[dispatch,languageParam])

	// Create rtl cache
	const cacheRtl = createCache({
		key: "muirtl",
		stylisPlugins: [prefixer, rtlPlugin],
	});

	let route;
	if (locale === "ar-AE") {
		route = (
			<CacheProvider value={cacheRtl}>
				<Routes />
			</CacheProvider>
		);
	} else {
		route = <Routes />;
	}

	useEffect(() => {
		let selectedCountry = localStorage.getItem('PRE_SELECTED_COUNTRY');
		if(selectedCountry !== null && selectedCountry !== ''){
			localStorage.setItem("PRE_SELECTED_COUNTRY", userCurrentLocation);
			window.addEventListener("beforeunload", preSelectCountryName);				
		}		
		return () => {
			window.removeEventListener("beforeunload", preSelectCountryName);
		};
	}, []);

	const preSelectCountryName = (e) => {
		if((window.location.pathname !== "/" &&  window.location.pathname !== "/login")) {
			localStorage.setItem("PRE_SELECTED_COUNTRY", "");
		}
	};

	if(currentCountry){
		return (
			<LocaleContext.Provider value={memoizedContextValue}>
			<Suspense fallback={<Loader />}>
				<HelmetProvider>
					<Helmet>
						{languageVal === "ar-AE"
							? document.body.setAttribute("dir", "rtl")
							: document.body.setAttribute("dir", "ltr")}
					</Helmet>
				</HelmetProvider>
				<div className="App">{route}</div>
			</Suspense>
		</LocaleContext.Provider>
		);
	}
	
}

export default App;
