/**
 * A reusable component that displays a subheader for a card.
 *
 * @param {string} value - The text content of the subheader.
 * @param {boolean} [leftAlign=false] - Whether to align the subheader to the left.
 */
import { Typography } from "@mui/material";
import "./style.scss";
import PropTypes from 'prop-types'

const CardSubHeader = ( props ) =>
{
	const {
		value,
		leftAlign
		 } = props
	return (
		<>
			{value && (
				<Typography
					sx={{ mt: 1 }}
					align={leftAlign ? "left" : "center"}
					className="subtext-cards"
				>
					{value}
				</Typography>
			)}
		</>
	);
};
CardSubHeader.propTypes = {
  value: PropTypes.string,
  leftAlign: PropTypes.string,
}

export default CardSubHeader;
