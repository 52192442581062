/**
 * A reusable component that displays a header and subheader for a card.
 *
 * @param {string} title - The text content of the header.
 * @param {string} subTitle - The text content of the subheader.
 * @param {string} className - An optional CSS class to apply to the header.
 */
import { Typography } from "@mui/material";
import CardSubHeader from "../CardSubHeader";
import PropTypes from 'prop-types'


const CardHeader = ( props ) =>
{
	const {
		title,
		className,
		subTitle
	    }=props
	
	return (
		<div>
			{title && (
				<Typography
					align="center"
					className={
						className ? `${className} heading-1` : "heading-1"
					}
				>
					{title}
				</Typography>
			)}

			{subTitle && <CardSubHeader value={subTitle} />}
		</div>
	);
};
CardHeader.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	subTitle: PropTypes.string,
}

export default CardHeader;
