/* istanbul ignore file */
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LogInComponent from "../pages/authentication/Login";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import ForgotPasswordConfirm from "../pages/authentication/ForgotPasswordConfirm";
import ResetPassword from "../pages/authentication/ResetPassword";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import ResetPasswordConfirmation from "../pages/authentication/ResetPasswordConfirmation";
import Loader from "../components/Loader";
import ForgotUsernameConfirm from "../pages/authentication/ForgotUsernameConfirm";
import ForgotUsername from "../pages/authentication/ForgotUsername";
import ExpiredLink from "../pages/authentication/ExpiredLink";

import FXCMErrorNotification from "../components/common/FXCMErrorNotification";
import OnboardingPage from "../pages/authentication/OnboardingPage";
import { useSelector } from "react-redux";
import FXCMGenericAlert from "../components/common/FXCMGenericAlert/FxcmGenericAlert";

const PlatformSelection = React.lazy(() => import("../pages/onboarding/PlatformSelection"));
const CurrencySelection = React.lazy(() => import("../pages/onboarding/CurrencySelection/CurrencySelection"));
const UserInfo = React.lazy(() => import("../pages/onboarding/UserInfo/UserInfo"));
const RiskDisclosure = React.lazy(() => import("../pages/onboarding/RiskDisclosure/RiskDisclosure"));
const TermsOfBusiness = React.lazy(() => import("../pages/onboarding/TermsOfBusiness"));
const UserDetailsMessage = React.lazy(() => import("../pages/onboarding/UserDetailsMessage/UserDetailsMessage"));
const DocumentUpload = React.lazy(() => import("../pages/Dashboard/DocumentUpload/DocumentUpload"));
const PageNotFound = React.lazy(() => import("../pages/PageNotFound"));
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const PaymentMainPage = React.lazy(() => import("../pages/Payments/PaymentMainPageV2"));
const TaxDetails = React.lazy(() => import("../pages/Payments/CRS/NewTax"));
const RejectionPage = React.lazy(() => import("../pages/onboarding/RejectionPage/RejectionPage"));
const ExpiredSession = React.lazy(() => import("../components/common/FXCMExpiredSession"));
const LiveChat = React.lazy(() => import("../components/layout/LiveChat"));

const RouterComponent = () => {
	const { user } = useSelector((state) => state.user);
	return (
		<div className="body-wrapper">
			<FXCMErrorNotification />
			<FXCMGenericAlert />
			<Router>
				<ExpiredSession isIdle={false} priorNotify={false} />
				<LiveChat />
				<Routes>
					<Route
						path="/"
						element={
							user?.authToken ? (
								<ProtectedRoute>
									<PlatformSelection />
								</ProtectedRoute>
							) : (
								<OnboardingPage key="onboardingPageId" />
							)
						}
					></Route>
					<Route
						path="/autoLogin"
						element={[
							// <EsmaBanner key="esmaBannerId" />,
							<OnboardingPage key="onboardingPageId" />,
						]}
					></Route>
					<Route
						path="/rb"
						element={[<OnboardingPage key="onboardingPageId" />]}
					></Route>
					<Route
						path="/validatepasswordtoken"
						element={[
							// <EsmaBanner key="esmaBannerId" />,
							<OnboardingPage key="onboardingPageId" />,
						]}
					></Route>
					<Route
						path="/platform"
						element={
							<ProtectedRoute>
								<PlatformSelection />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path="/currency-select"
						element={
							<ProtectedRoute>
								<CurrencySelection />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path="/userInfo"
						element={
							<ProtectedRoute>
								<UserInfo />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path="/riskdisclosure"
						element={
							<ProtectedRoute>
								<RiskDisclosure />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path="/termsofbusiness"
						element={
							<ProtectedRoute>
								<TermsOfBusiness />
							</ProtectedRoute>
						}
					></Route>
					<Route
						path="/success"
						element={
							<ProtectedRoute>
								<UserDetailsMessage />
							</ProtectedRoute>
						}
					></Route>
					<Route path="/login" element={<LogInComponent />}></Route>
					<Route path="/forgotusername" element={<ForgotUsername />}></Route>
					<Route path="/expiredLink" element={<ExpiredLink />}></Route>
					<Route path="/forgotpassword" element={<ForgotPassword />}></Route>
					<Route
						path="/forgotpasswordConfirm"
						element={<ForgotPasswordConfirm />}
					></Route>
					<Route path="/resetpassword" element={<ResetPassword />}></Route>
					<Route
						path="/resetPasswordConfirmation"
						element={<ResetPasswordConfirmation />}
					></Route>
					<Route path="/loader" element={<Loader />}></Route>
					<Route
						path="/docUpload"
						element={
							<ProtectedRoute>
								<DocumentUpload />
							</ProtectedRoute>
						}
					></Route>

					<Route
						path="/deposit"
						element={
							<ProtectedRoute>
								<PaymentMainPage />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/tax"
						element={
							<ProtectedRoute>
								<TaxDetails />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/rejection"
						element={
							<ProtectedRoute>
								<RejectionPage />
							</ProtectedRoute>
						}
					></Route>

					<Route path="/dashboard" element={<Dashboard />}></Route>
					<Route path="*" element={<PageNotFound />} />
					<Route
						path="/forgotUsernameConfirm"
						element={<ForgotUsernameConfirm />}
					></Route>
				</Routes>
			</Router>
		</div>
	);
};

export default RouterComponent;
