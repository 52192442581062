/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { configurationServices } from "../../constants/configurationServices";
import { useTranslation } from "react-i18next";
import {
	Autocomplete,
	Button,
	FormControl,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import moment from 'moment';
import { Box } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import Header from "../../components/layout/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "../../reducers/userInfoSlice";
import { forgotUsenameService, systemAlertFunc } from "../../services/auth.service";
import { userName } from "../../constants/ImageConstants";
import { analytics } from "../../utils/analytics/analytics";
import useDirectionHook from "../../hooks/useDirectionHook";
import { removeForgotUsername } from "../../reducers/signUpSlice";
import { notifyApiFail } from "../../reducers/errorNotifySlice";
import FXCMTrans from "../../components/common/FXCMTrans";
import { DateTime } from "luxon";
import "./style.scss";

const ForgotUsername = () => { //NOSONAR
	const minEligibleDOB = DateTime.now().minus({ years: 18 });
	const { t: getLabel } = useTranslation();
	const navigate = useNavigate();
	const [config, setConfig] = useState();
	const dispatch = useDispatch();
	const [error, setError] = useState({
		lastname: false,
		email: false,
		phonenumber: false,
	});
	const { forgotUserRes, getAllEnLabels } = useSelector((state) => state.signUp);
	const { languageParam, user } = useSelector((state) => state.signUp);
	const [direction] = useDirectionHook(languageParam);

	const [dateValue, setDateValue] = useState(null);
	const [age, setAge] = useState();
	const [countryCodeError, setCountryCodeError] = useState(false);
	const [phoneCountry, setphoneCountry] = useState();
	const [countryCode, setCountryCode] = useState("");
	const [countriesList, setCountryList] = useState();
	const[buttonLoader, setButtonLoader] = useState(false);
	const [values, setValues] = useState({
		lastname: "",
		email: "",
		dob: dateValue,
		phonenumber: "",
		phoneCountry: phoneCountry
	});
	const defaultLocale =localStorage.getItem("i18nextLng");
	const [dateFormateValidation,setDateFormateValidation] = useState(false) 
	 
/* istanbul ignore next */
	const updateAboutUser = (newValue, type) => {
		if (type === "dob") {
			if(newValue?.c){
			const DOB = moment(newValue?.toJSDate()).format("YYYY-MM-DD");
			const finalAge = DateTime.now().diff(DateTime.local(newValue?.c?.year, newValue?.c?.month, newValue?.c?.day), ['days', 'hours', 'years']);			
			setAge(finalAge.values.years);
			setDateValue(newValue);
			setValues({ ...values, dob: DOB });
			} else{
				const DOB = moment(newValue?.toJSDate()).format("YYYY-MM-DD");				
				setAge(newValue === null ? undefined : newValue);
				setDateValue(newValue);
				setValues({ ...values, dob: DOB });
			}
		}
	};
	
/* istanbul ignore next */
	useEffect(() => {
		if (
			forgotUserRes &&
			forgotUserRes !== "" &&
			Object.keys(forgotUserRes).length !== 0
		) {
				dispatch(removeForgotUsername());
				navigate("/forgotUsernameConfirm", {
					state: { email: values.email },
				});
				
				setButtonLoader(false);
			
		}
	}, [forgotUserRes, dispatch, navigate, values]);

	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( // NOSONAR
			values.email
		);

/* istanbul ignore next */
	const handleChange = (prop) => (event, newValue) => {
		setValues({ ...values, [prop]: event.target.value });

		if (prop === "dob") {
			const DOB = moment(newValue).format("DD-MM-YYYY");
			setValues({ ...values, [prop]: DOB });
			setAge(moment().diff(newValue, "years", false), "age");
			setDateValue(newValue);
		}
		if (
			event?.target?.value?.includes("fuhui") ||
			event?.target?.value?.includes("fxcm")
		) {
			setError({ ...error, email: true });
		}
	};
 
/* istanbul ignore next */
	const handleBlurEmail = () => {
		if (emailRegex) {
			setError({ ...error, email: false });
		} else {
			setError({ ...error, email: true });
		}
	};

/* istanbul ignore next */
	const handleCancelClick = () => {
		analytics?.trackEvent("targeted_click", { engagementContext:  getAllEnLabels["CANCEL"] });
		navigate("/login");
	};

	const ageData = {
		column: 12,
		label: "dob",
		maxAge: 100,
		minAge: 18,
		required: true,
		type: "AgeDatePicker",
		value: "dob",
	};
 
/* istanbul ignore next */
	const validation = () => {
		let isValid = true;
		if (error.lastname || error.email || error.phonenumber) {
			isValid = false; // NOSONAR
		}
		if (
			values.email === "" ||
			values.lastname === "" ||
			values.phonenumber === ""
		) {
			isValid = false; // NOSONAR
		}
		if (age < ageData.minAge || age > ageData.maxAge || !dateValue) {
			isValid = false;
		} else {
			isValid = true;
		}
		return isValid;
	};

	useEffect(()=>{
		if(dateValue){		
			const formattedDate = dateValue.toFormat((defaultLocale === "zh-CN" || defaultLocale === "zh-TW") ?"yyyy/MM/dd":"MM/dd/yyyy");
			const jsDate = dateValue?.toJSDate();
			let formatValidation = moment(jsDate).format((defaultLocale === "zh-CN" || defaultLocale === "zh-TW") ?"YYYY/MM/DD":"MM/DD/YYYY") === formattedDate;
			setDateFormateValidation(formatValidation);
		}	
	},[dateValue])

	/* istanbul ignore next */
	const datePickerFormatLabel = (defaultLocale === "zh-CN" || defaultLocale === "zh-TW") ? "yyyy/MM/dd" : "MM/dd/yyyy" ;	 
	 
/* istanbul ignore next */
	const dateValidation =
		age < ageData.minAge || age > ageData.maxAge || dateValue === null;
		
	/* istanbul ignore next */
	const handleSubmit = (e) => {
		e.preventDefault();
		let emailError = false;
		let lastnameError = false;
		let phonenumberError = false;

		if (values.email === "") {
			emailError = true;
		}
		if (values.username === "") {
			lastnameError = true;
		}

		if (values.phonenumber === "") {
			lastnameError = true;
		}

		setError({
			...error,
			email: emailError,
			lastname: lastnameError,
			phonenumber: phonenumberError,
		});

		if (validation()) {
			setButtonLoader(true);
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			setValues({ ...values });
			analytics?.trackEvent("forgot_username", {});
			dispatch(forgotUsenameService(values));
			console.log(values)
		}
	};
 
/* istanbul ignore next */
	const fetchJson = () => {
		fetch(configurationServices().LogInURL)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setConfig(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};
	useEffect(() => {
		fetchJson();
	}, []);
 
/* istanbul ignore next */
	const fetchCountryJson = () => {
		let countriesList = configurationServices().countryList;
		fetch(countriesList)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setCountryList(data);
			})
			.catch((e) => {
				console.log(e.message);
			});
	};

	useEffect(() => {
		fetchCountryJson();
	}, []);
	/* istanbul ignore next */
	function countryToFlag(value) {
		const code = value?.split(" ")[0];
		setCountryCode(code);
		if (Array.isArray(countriesList)) {
			return countriesList?.find((country) => country.country_code === code)?.image;
		}
		return undefined;
	}

	let enabled = !(emailRegex && values.email !== "" && values.lastname !== "" && values.phonenumber && !dateValidation && countryCode !== "" && dateFormateValidation);

	return (
		<Box mt="144px">
            <Header screenType="forgotusername" />
			<Box sx={{ mt: "97px" }}>
				<div className="container-home forgot-username">
					<Box textAlign="center">
						<img src={userName} alt="logInIcon" className="cardIcon" />
						<Typography className="heading-1" sx={{ mt: "20px" }}>
							{getLabel(config?.FORGET_USERNAME_TITLE)}
						</Typography>
					</Box>

					<form>
						<FormControl
							fullWidth
							sx={{ mt: "20px" }}
							className={
								direction.status
									? "custom-form-controler custom_form_controler_arabic"
									: "custom-form-controler"
							}
						>
							<TextField
								value={values.lastname}
								label={getLabel(config?.LASTNAME)}
								onChange={handleChange("lastname")}
								inputProps={{ maxLength: 50 }}
								error={error.lastname ? true : false}
								helperText={
									error.lastname && config && config.MANDATORY_CHECK //NOSOANAR
										? getLabel(config.MANDATORY_CHECK)
										: ""
								}
							/>
						</FormControl>

						<FormControl
							fullWidth
							sx={{ mt: "20px" }}
							className={
								direction.status
									? "custom_form_controler_arabic custom-form-controler cutome-date-picker"
									: "custom-form-controler  cutome-date-picker"
							}
						>
							<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={languageParam}>
								<DesktopDatePicker
									label={getLabel(config?.DATE_OF_BIRTH)}
									value={dateValue}
									onChange={	/* istanbul ignore next */
										( newValue ) =>
										updateAboutUser(newValue, ageData.label)
									}
									className={
										age < ageData.minAge ||
										age > ageData.maxAge ||
										dateValue?.c === null
										? ""
										: "input-text-normal"
									}																	
									inputFormat={datePickerFormatLabel}
									minDate={
										moment().subtract(ageData.maxAge, "years")._d
									}
									maxDate={
										moment().subtract(ageData.minAge, "years")._d
									}
									disableFuture
									defaultCalendarMonth={minEligibleDOB}
									renderInput={({ error, ...params }) => {
										
										return (
											<TextField
											error={
												age < ageData.minAge ||
												age > ageData.maxAge ||
												dateValue?.c === null
											}
											helperText={
												(age && (age < ageData.minAge || age > ageData.maxAge))
												? getLabel("AGE_DURATION")
												: dateValue?.c === null && user?.countryOfResidence === "CN" ? getLabel("DATE_FORMAT")//NOSONAR
												: dateValue?.c === null//NOSONAR
												? getLabel(["zh-CN","zh-TW"].includes(defaultLocale) ? "VALID_DATE_CHINA" : "VALID_DATE")//NOSONAR
												: ["zh-CN","zh-TW"].includes(defaultLocale)   ? getLabel("DATE_FORMAT") : 'mm/dd/yyyy' //NOSONAR
											}											
											{...params}
										/>
										)

									}}
								/>
							</LocalizationProvider>
						</FormControl>

						<FormControl
							fullWidth
							sx={{ mt: "20px" }}
							className={
								direction.status
									? "custom-form-controler custom_form_controler_arabic"
									: "custom-form-controler"
							}
						>
							<TextField
								value={values.email}
								label={getLabel(config?.EMAIL)}
								onChange={handleChange("email")}
								name="email"
								inputProps={{ maxLength: 50 }}
								onBlur={handleBlurEmail}
							// error={error.email ? true : false}
							// helperText={
							// 	error.email
							// 		? fxcmError
							// 			? getLabel("Doesn't Exist")
							// 			: mandatoryError.email
							// 			? getLabel(config.MANDATORY_CHECK)
							// 			: getLabel("Doesn't Exist")
							// 		: ""
							// }
							/>
						</FormControl>

						<Box display="flex" sx={{ mt: "30px" }}>
							<Grid container spacing={1} className="custom-form-controler">
								<Grid item xs={4}>
									<Autocomplete
										//sx={{width: "145px"}}
										options={countriesList}
										className="contact-auto-complete no-asterisk forgot-usernam-auto"
										getOptionLabel={	/* istanbul ignore next */
											( option ) =>
											`${option?.country_code} (+${option?.phone_prefix})`
										}
										value={phoneCountry}
										onChange={/* istanbul ignore next */
											(event, newValue) => {	
											setphoneCountry(newValue?.country_code);	
											setValues({ 
												...values,
												phoneCountry: newValue?.country_code
											});
											if (!newValue) setCountryCode("");
											else setCountryCodeError(false);
										}}
										clearIcon
										renderOption={/* istanbul ignore next */
											( props, option ) => (
											<Box
												component="li"
												marginLeft={"-20px"}
												sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
												{...props}
											>
												<img
													loading="lazy"
													width="20"
													src={option?.image}
													alt=""
												/>
												{option?.country_code}(+{option?.phone_prefix})
											</Box>
										)}
										renderInput={(params) => {
											return (
												<>
													{params?.inputProps?.value && (
														<span
															style={{
																position: "absolute",
																transform: "translateY(50%)",
																marginLeft: "15px"
															}}
														>
															<img
																loading="lazy"
																width="20"
																src={countryToFlag(params?.inputProps.value)}
																alt=""
															/>
														</span>
													)}
													<TextField
														{...params}
														// className="custom-form-controler"
														label={getLabel("COUNTRY_CODE")}
														inputProps={{
															...params.inputProps,
															style: { marginLeft: "20px", height: "25px" }
														}}
														sx={{
															"& label": {
																padding: "1px",
																fontSize: "14px"
															}
														}}
														error={countryCodeError}
														helperText={
															countryCodeError ? (
																<FXCMTrans
																	defaults={getLabel("MANDATORY_CHECK")}
																/>
															) : (
																""
															)
														}
														size="small"
													/>
												</>
											);
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormControl
										sx={{ marginLeft: "10px" }}
										className={
											direction.status
												? "custom-form-controler custom_form_controler_arabic"
												: "custom-form-controler"
										}
									>
										<TextField
											sx={{ width: "137%" }}
											label={getLabel(config?.PHONE_NUMBER)}
											value={values.phonenumber}
											onChange={handleChange("phonenumber")}
											type="number"
											error={error.phonenumber ? true : false}
											helperText={
												error.lastname ? getLabel(config?.MANDATORY_CHECK) : ""
											}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>

						<Box sx={{ mt: 3 }}>
							<Grid container spacing={1} className="forgot-button">
								<Grid item xs={6}>
									<Button
										fullWidth
										variant="outline"
										sx={{ my: 1 }}
										onClick={handleCancelClick}
									>
										{getLabel("CANCEL")}
									</Button>
								</Grid>
								<Grid item xs={6}>
									<LoadingButton
										loading={buttonLoader}
										loadingPosition="end"
										type="submit"
										disabled={enabled}
										fullWidth
										sx={{ mt: 1 }}
										variant="contained"
										onClick={handleSubmit}
									>
										{getLabel("SUBMIT")}
									</LoadingButton>
								</Grid>
							</Grid>
						</Box>
					</form>
				</div>
			</Box>
        </Box>
	);
};

export default ForgotUsername;
