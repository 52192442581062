/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/**
 * A reusable component that renders translated text with customizable HTML elements.
 *
 * @param {object} props - The props passed to the component.
 * @param {string} props.i18nKey - The i18n key of the text to translate.
 * @param {object} props.values - Optional values to customize the rendered HTML elements.
 * @param {string} props.values.spanClass - The CSS class to apply to the span element.
 * @param {string} props.values.anchorClass - The CSS class to apply to the anchor element.
 * @param {string} props.values.url - The URL to link to for the anchor element.
 */

import React from "react";
import { Trans } from "react-i18next";
import "./style.scss";
import { Link } from "react-router-dom";

const FXCMTrans = (props) => {
	return (
		<Trans
			{...props}
			components={{
				span: <span />,
				u: <u />,
				b: <b />,
				ul: <ul />,
				li: <li />,
				a: <a />, //NOSONAR
				Link: <Link/>
			}}
		/>
	);
};

export default FXCMTrans;
