/**
 * A reusable component that displays an error notification based on the `apiFail` state.
 */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { useTranslation } from "react-i18next";
import { notifyApiFail } from "../../../reducers/errorNotifySlice";
import "../FXCMErrorNotification/style.scss";

const FXCMErrorNotification = () => { // NOSONAR
	const { t: getLabel } = useTranslation(); 

	const [open, setOpen] = useState(false);
	const [errorType, setErrorType] = useState("Failed");
	const [errorTitle, setErrorTitle] = useState("");
	const [errorDesc, setErrorDesc] = useState("");
	const apiFail = useSelector((state) => state.apiFail);
	const { languageParam, authError } = useSelector((state) => state.signUp);
	const dispatch = useDispatch();


	useEffect(() => { // NOSONAR
		if (apiFail && apiFail?.error && apiFail.error !== null) { // NOSONAR
			if (apiFail && apiFail.error && apiFail.error.title && apiFail.error.desc && apiFail.error.type) { // NOSONAR
				const { title, desc, type } = apiFail.error;
				setErrorTitle(title);
				setErrorDesc(desc);
				setErrorType(type);
			} else {
				setErrorTitle(getLabel("AUTHORIZATION_FAILED_TITLE"))
				setErrorDesc(getLabel("AUTHORIZATION_FAILED"))
			}
			setOpen(true);
			if ( ( authError && authError.message === 'USER_EMAIL_ALREADY_EXIST' ) ||
				 ( authError && authError.message === 'USER_ACCOUNT_LOCKED_FXMA' ) ||
				 (authError && authError.message === 'USER_ONBOARDING_INCOMPLETE') ||
				 (authError && authError.message === 'USER_ACCOUNT_LOCKED_CX_LOGIN') ||
				 (authError && authError.message === 'RB not allowed'))
				{
				setTimeout(() => {
					setOpen(false);
					dispatch(notifyApiFail(null));
				}, 2500);
			} else {
				setTimeout(() => {
					setOpen(false);
					dispatch(notifyApiFail(null));
				}, 10000);
			}
		}

		return () => {

			dispatch(notifyApiFail(null));
		};
	}, [apiFail, dispatch, getLabel, authError]);

	const handleClose = () => {
		setOpen(false);
		dispatch(notifyApiFail(null));
	};

	if (errorTitle && errorDesc) {
		return (
			<>
				{ open && (
					<div
						className={							
							`${errorType === 'warning' ? 'warning-notification' : "" }
							${errorType === 'arabicWarning' ? 'warning-notification arabicWarning-notification' : 'error-notification' }`
						}
					>
						{errorType === "warning" || errorType === "arabicWarning" ? (
							<span className="material-symbols-outlined warning-icon">warning</span>
						) : (
							<HighlightOffOutlinedIcon
								className="cross-icon"
								sx={{ paddingLeft: "20px", marginBottom: "-29px" }}
							/>
						)}
						<p
							className={
								errorType === "warning" || errorType === "arabicWarning" ? "error-text-warning" : (languageParam === 'ar-AE' ? "errText-Arabic" : "error-text") // NOSONAR
							}
						>
							<span
								style={{
									fontWeight: "700",
									fontSize: "97%",
									color: "black",
									fontFamily: "Noto Sans",
								}}
								data-testid="error-title"
							>
								{errorTitle}
							</span>
							<br></br>
							<span style={{ fontSize: "90%", color: "GrayText" }} data-testid="error-description">
								{errorDesc}
							</span>
						</p>
						<ClearSharpIcon
							className={
								errorType === "warning" || errorType === "arabicWarning" ? "close-icon-warning" : (languageParam === "ar-AE" ? "closeIcon-Arabic" : "close-icon") // NOSONAR
							}
							onClick={handleClose}
						/>
					</div>
				)}
			</>
		)
	}

};

export default FXCMErrorNotification;
