/**
 * A reusable Drawer component with a title and sub-title which is visible on mobile and tab views.
 *
 * @param {string} props - It has Title and Sub-Title to display.
 */
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { notificationIcon, ClosePopUp } from "../../constants/ImageConstants";
import "./style.scss";
import PropTypes from 'prop-types'

const Drawer = (props) => {
  const [ open, setOpen ] = React.useState( false ); // NOSONAR
  console.log(open)
  const handleClose = () =>{
    /* istanbul ignore next */
    setOpen(false);
  };
  return (
    <>
      <Box className="notification-Icon">
        <img src={notificationIcon} alt="notifiIcon" />
        <img
          src={ClosePopUp}
          className="closePopIcon"
          alt="notifiIcon"
          data-testid="functionCall"
          // onClick={handleClose}
        />
      </Box>
      <Typography className="drawer-title">{props.popUpTitle}</Typography>
      <Typography className="subtext-cards">{props.popupSubtitle}</Typography>
      <div className="button-group">
        <Button variant="outlined" onClick={props.handleLogout}>
          {props.logout}
        </Button>
        <Button variant="contained" onClick={handleClose} autoFocus>
          {props.continue}
        </Button>
      </div>
    </>
  );
};
Drawer.propTypes = {
  popUpTitle: PropTypes.string.isRequired,
  popupSubtitle: PropTypes.string.isRequired,
  handleLogout: PropTypes.string.isRequired,
  logout: PropTypes.string.isRequired,
  continue: PropTypes.string.isRequired,
}


export default Drawer;
