/* istanbul ignore file */
import Cookies from 'js-cookie';
import { updateUserInfo } from "../reducers/userInfoSlice";
import { store } from "../store";
import { notifyApiFail } from "../reducers/errorNotifySlice";
import { customAxios } from "./serviceInterceptor";
import { getAPIPath } from "../utils/endPointMapper";
import { getHeaders } from "../utils/getUsersDetails";
import { systemAlertFunc } from "./auth.service";
import { setSectionFailKey } from "../reducers/commonSlice";

const API_URL = getAPIPath().REACT_APP_BASE_URL;
export const userInfoData =
	(sectionResponse, requestObject) => async (dispatch) => {
		const state = store.getState();
		const users = [];
		if (sectionResponse?.serviceName === "tob") {
			requestObject["esmaWarning"] = state.signUp.esmaText;
		}
		users.push(requestObject);
		const requestBody = {
			users: users,
		};
		const requestObj = {
			processName: "updateUser",
			requestBody,
			methodType: "POST",
		};
		if (sectionResponse?.serviceName) {
			requestObj.serviceName = [sectionResponse?.serviceName];
		}
		const config = {
			...getHeaders(state?.signUp?.authToken, state?.user?.user)
		};
		return await customAxios
			.post(API_URL, requestObj, config)
			.then((response) => {
				if (response.status === 200 && response.data.success === true) {
					dispatch(updateUserInfo(sectionResponse));
					if (sectionResponse?.serviceName === "hrin") {
						if (!requestObject.hrinStatus) {
							dispatch(updateUserInfo({ triggerLogout: true }))
						}
					}
				} else {
					let message =
						response.data && response.data.message
							? response.data.message
							: "Something went wrong.";
					dispatch(notifyApiFail(message));
					dispatch(systemAlertFunc(config?.headers?.requestid, response?.data?.httpStatus, response?.data?.message));
				}
			})
			.catch((err) => {
				if (err?.response?.data?.status == 400 && Object.keys(err?.response?.data?.validationErrorMap)?.length > 0) {
					dispatch(setSectionFailKey(true));
				} else { 
					dispatch(notifyApiFail("SOMETHING_WENT_WRONG"));
					dispatch(systemAlertFunc(config?.headers?.requestid, err?.response?.data?.httpStatus, err?.response?.data?.message));
				}

			});
	};
