/* istanbul ignore file */
import { getEsmabarText } from "../constants/configurationServices";
import { notifyApiFail } from "../reducers/errorNotifySlice";
import {
	autentication,
	authenticationError,
	autoLoginResponseData,
	esmaBarResponse,
	forgotPasswordResponse,
	forgotUsernameResponse,
	linkExpiredValidationResponse,
	loginResponse,
	resetPasswordRes,
} from "../reducers/signUpSlice";
import { store } from "../store";
import { resetAll } from "../reducers/resetSlice";
import { customAxios } from "./serviceInterceptor";
import { getAPIPath } from "../utils/endPointMapper";
import { getHeaders, getRequestHeaders } from "../utils/getUsersDetails";
import Cookies from 'js-cookie';

/* istanbul ignore next */
const signUpURL = getAPIPath().REACT_APP_SIGNUP_URL;
const loginURL = getAPIPath().REACT_APP_LOGIN_URL;
const appURL = getAPIPath().REACT_APP_URL;
const applicationVersion = process.env.REACT_APP_APPLICATION_VERSION;
const domain_url = window.location.origin;

/* istanbul ignore next */
export const systemAlertFunc = (requestIdNumber, errorType, errorMsg) => async (dispatch) => {
	const config = {
		headers: {
			requestid: requestIdNumber,
		},
	};
	const requestObj = {
		processName: "updateSystemAlert",
		feErrorMsg: errorMsg ? errorMsg : "Something went Wrong",
		feErrorType: errorType ? errorType : "Failure",
		methodType: "POST",
	};
	/* istanbul ignore next */
	return await customAxios
		.post(appURL + "/updateSystemAlert", requestObj, config)
};


export const authenticateUser =
	(userData, esmatext, ip, rb_name, campaignId,  product, languageParam,trackingId,coreg,gclId,irpid) => async (dispatch) => {//NOSONAR
		let selectedLang = localStorage.getItem("i18nextLng");
		userData.language = selectedLang;
		let user = [];
		user.push({
			username: userData.email,
			email: userData.email,
			password:userData.password,
			countryOfResidence: userData.country,
			emailConsent: userData.emailConsent,  
			smsConsent: userData.smsConsent, 
			marketingConsent: userData.marketingConsent,
			locale:  languageParam ? languageParam : userData.language ?  userData.language : "en-US",//NOSONAR
			esmaWarning:
				userData.entity === "FXCM_EU" || userData.entity === "FXCM_UK"
					? esmatext
					: "",
			entity: userData.entity,
			ipAddress: ip,
			applicationVersion: applicationVersion,
			userMarketingFields:{
                trackingId:trackingId,
                campaignId:campaignId,
                product:product,
				coreg:coreg,
				gclid:gclId,
				irpid:irpid
            }
		});
		/* istanbul ignore next */
		if (rb_name) {
			user[0].rbName = rb_name;
		}
		
		const requestParams = {
			users: user,
			domainName: window.location.origin
		};
		const requestObj = {
			processName: "signUp",
			requestBody: requestParams,
			methodType: "POST",
		};
		const config = {
			...getRequestHeaders()
		};
		return await customAxios
			.post(signUpURL, requestObj, config)
			.then((response) => {
				/* istanbul ignore next */
				if (response.data.status === 406 || response.data.status === 404 || response.data.status === 400) {
					dispatch(authenticationError(response.data));
				} else {
					Cookies.set("token", response?.data?.data?.["login-response"]?.token);
					dispatch(autentication(response.data));
				}
			})
			.catch((err) => {
				/* istanbul ignore next */
				if (err?.response?.data?.status === 404 || err?.response?.data?.status === 406 || err?.response?.data?.status === 400) {
					dispatch(authenticationError(err?.response?.data));
				} else if (err?.response?.data?.status === 500 && ["Invalid Password !", "Invalid Email !"].includes(err?.response?.data?.message)) {
					dispatch(authenticationError(err?.response?.data));
				}
				else { 
					dispatch(notifyApiFail(err.message));
					dispatch(systemAlertFunc(config?.headers?.requestid, err?.httpStatus, err?.message));
					dispatch(authenticationError({}));
				} 
				
			});
	};
export const loginService = (loginData) => async (dispatch) => {
	let user = [];
	user.push({
		email: loginData.email,
		password: loginData.password,
		username: loginData.email,
	});
	const requestParams = {
		users: user,
	};
	const requestObj = {
		processName: "login",
		requestBody: requestParams,
		methodType: "POST",
	};
	const config = {
		...getRequestHeaders()
	};
	return await customAxios
		.post(loginURL, requestObj, config)
		.then((response) => {
			/* istanbul ignore next */
			if(response?.data?.data?.["login-response"]) {//NOSONAR
				Cookies.set('token',response?.data?.data?.["login-response"]?.token)
			}
			dispatch(loginResponse(response?.data));
		})
		.catch((err) => {
			console.log(err);
       /* istanbul ignore next */
			if (
				err &&//NOSONAR
				err.response &&
				err.response.data &&
				err.response.data.additionalProperties
			) {
				  const customData = {
					...err.response.data,
					data: {
					  ...err.response.data.data,
					  "user-details": {
						...err.response.data.data?.["user-details"],
						...{
							userLocale: localStorage.getItem('i18nextLng'),
						  },
					  },
					},
				  };
				  dispatch(loginResponse(customData));				  
			} else if (
				err &&//NOSONAR
				err.response &&
				err.response.data &&
				err.response.data.status === 401
			) {				
				const customData1 = {
					...err.response.data,
					data: {
					  ...err.response.data.data,
					  "user-details": {
						...err.response.data.data?.["user-details"],
						...{
							userLocale: localStorage.getItem('i18nextLng'),
						  },
					  },
					},
				  };
				  dispatch(loginResponse(customData1));	
			} else {
				dispatch(notifyApiFail(null));
				dispatch(systemAlertFunc(config?.headers?.requestid, err?.code, err?.message));
			}
		});
};

const redirectPage = (userData) => {
	return new Promise((resolve)=>{
		Cookies.remove("token");
		const keyToKeep = 'i18nextLng';
		for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);
			if (key !== keyToKeep) {
				localStorage.removeItem(key);
			}
		}
		store.dispatch(resetAll());
		let isRedirectToLogin = true;
		
		if (userData?.logoutRedirectURL) {
			isRedirectToLogin = false;
			window.onbeforeunload = null;
			window.location.href = userData?.logoutRedirectURL;			
		}
		resolve(isRedirectToLogin);
	})
}

export const logoutService = (userData, logoutData) => async (dispatch) => {
	const allStates = store.getState();
	const { user } = allStates?.user || {};
	const ipAddress = allStates?.ipAddress;
	const requestParams = {
		ipAddress: ipAddress?.ip,
		domainName: domain_url,
		token: user.authToken
	};
	const requestObj = {
		processName: "logout",
		requestBody: requestParams,
		methodType: "POST",
	};
	const config ={
		...getHeaders(user.authToken, user)
	}
	return await customAxios
		.post(appURL + "/logout", requestObj,config)
		.then((response) => {
			if (response?.data?.success) {
				
				redirectPage(userData)
					.then((isRedirectToLogin)=>{
						
						if(isRedirectToLogin) {
							userData.navigate("/login");
						}
						return true;				
					})
					.then((data)=>{
						setTimeout(() => {
							window.location.reload();
						}, 500);
					})
			} else {
                window.location.reload(false);
            }
		})
		.catch((err) => {
			/* istanbul ignore next */
			dispatch(notifyApiFail("Something went wrong"));
			dispatch(systemAlertFunc(config?.headers?.requestid, err?.response?.data?.httpStatus, err?.response?.data?.message));
		});
};
export const forgotUsenameService = (forgotUsername) => async (dispatch) => {
	const requestParams = {
		lastName: forgotUsername.lastname,
		email: forgotUsername.email,
		dateOfBirth: forgotUsername.dob,
		phoneNumber: forgotUsername.phonenumber,
		phoneCountry: forgotUsername.phoneCountry
	};
	const requestObj = {
		processName: "forgotUsername",
		requestBody: requestParams,
		methodType: "POST",
	};
	const config = {
		...getRequestHeaders()
	};
	return await customAxios
		.post(appURL + "/forgot-username", requestObj, config)
		.then((response) => {
			dispatch(forgotUsernameResponse(response.data));
		})
		.catch((err) => {
			const errData = err?.response?.data; const reqId= config?.headers?.requestid;
			const allData = {errData, reqId}
			dispatch(forgotUsernameResponse(allData));
		});
};

export const ForgotPasswordService = (forgotPassword) => async (dispatch) => {
	const requestParams = {
		email: forgotPassword.email,
		domainName: domain_url
	};
	const requestObj = {
		processName: "resetPassword",
		requestBody: requestParams,
		methodType: "POST",
	};
	const config = {
		...getRequestHeaders()
	};
	return await customAxios
		.post(appURL + "/resetpassword", requestObj, config)
		.then((response) => {
			dispatch(forgotPasswordResponse(response.data));
		})
		.catch((err) => {
			const errData = err.response.data; const reqId= config.headers.requestid;
			const allData = {errData, reqId}
			dispatch(forgotPasswordResponse(allData));
		});
};
export const linkExpiredValidationService = (token) => async (dispatch) => {
	const requestParams = {
		token: token,
		domainName: domain_url
	};
	const requestObj = {
		processName: "validatePasswordToken",
		requestBody: requestParams,
		methodType: "POST",
	};
	return await customAxios
		.post(appURL + "/validatepasswordtoken", requestObj)
		.then((response) => {
			dispatch(linkExpiredValidationResponse(response.data));
		})
		.catch((err) => {
			dispatch(linkExpiredValidationResponse(err.response.data));
		});
};
export const saveandResumeTokenValidation = (token, ip) => async (dispatch) => {
	const requestParams = {
		token: token,
		ipAddress: ip,
		domainName: domain_url
	};
	const requestObj = {
		processName: "autoLogin",
		requestBody: requestParams,
		methodType: "POST",
	};
	const config = {
		...getRequestHeaders()
	};
	return await customAxios
		.post(appURL + "/auto-login", requestObj, config)
		.then((response) => {
			if(response?.data?.data) {
				dispatch(autoLoginResponseData(response.data));
			} else {
				window.location.replace("/login");
			}
		})
		.catch((err) => {
			dispatch(notifyApiFail("SOMETHING_WENT_WRONG"));
      dispatch(systemAlertFunc(config?.headers?.requestid, err?.response?.data?.httpStatus, err?.response?.data?.message));
			window.location.replace("/login");
		});
};

export const resetPasswordService =
	(resetPassword, email) => async (dispatch) => {
		const requestParams = {
			email: email,
			newPassword: resetPassword,
			token: localStorage.getItem("uPassToken")
		};
		const requestObj = {
			processName: "updatePassword",
			requestBody: requestParams,
			methodType: "POST",
		};
		const config = {
			...getRequestHeaders()
		};
		return await customAxios
			.post(appURL + "/updatepassword", requestObj, config)
			.then((response) => {
				dispatch(resetPasswordRes(response.data));
			})
			.catch((err) => {
				dispatch(resetPasswordRes(err.response.data));
				if(err?.response?.status === 504) dispatch(notifyApiFail("Something went wrong"));
				dispatch(systemAlertFunc(config?.headers?.requestid, err?.response?.data?.httpStatus, err?.response?.data?.message));
			});
	};
export const fetchEsmaBarText = (userEntity) => async (dispatch) => {
	let esmaTextData = getEsmabarText(userEntity);
	return await customAxios
		.get(esmaTextData)
		.then((response) => {
			dispatch(esmaBarResponse(response.data));
		})
		.catch((err) => {
			dispatch(esmaBarResponse(err.response.data));
		});
};
