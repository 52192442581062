/**
 * A component that displays Progress br across the application based on the route and sectionName.
 *
 * @param {string} sectionName - The name of the section.
 * @param {string} section - For handling various conditions within the section.
 */
import React from 'react';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export const FXCMAutoCompleted = ( props ) =>{
    const {
        cssStyles,
        countryList,
        onOptionChange,
        selCountry,
        renderDropdownList,
        direction,
        countryToFlag,
        label,
        textFieldSize,
        error,
        displayError,
        errorMessage

    } = props
    const { t: getLabel } = useTranslation();

  return (
    <div>
        <Autocomplete
            className={cssStyles}
            id='country-select-demo'
            options={countryList}
            getOptionLabel={option => getLabel(option.country_desc)}
            onChange={(event, newValue) => onOptionChange(newValue)}
            value={selCountry}
            renderOption={(props, option) => (
                <div>{renderDropdownList(props, option)}</div>
            )}
            renderInput={params => (
                <>
                {params.inputProps.value && (
                    <span
                    className={direction.status ? 'country-flag-arabic' : 'country-flag'}
                    >
                    <img
                        loading='lazy'
                        width='34'
                        src={countryToFlag(params.inputProps.value)}
                        alt=''
                    />
                    </span>
                )}
                <TextField
                    {...params}
                    sx={{
                    '.MuiInputBase-root': {
                        '&::before': {
                        background: !params.inputProps.value
                            ? `url(${
                                process.env.REACT_APP_CDN_URL + '/images/search-icon.svg'
                            })`
                            : ''
                        }
                    }
                    }}
                    label={label}
                    inputProps={{
                    ...params.inputProps
                    }}
                    size={textFieldSize}
                        error={error}
                        className={errorMessage === "traduRedirect" ? "success-helper" : ""}
                    helperText={
                        error ? (
                            <FormHelperText>{displayError()}
                            </FormHelperText>) : ''
                    }
                />
                </>
            )}
        />

    </div>
  )
}
FXCMAutoCompleted.propTypes = {
  cssStyles: PropTypes.string,
  countryList: PropTypes.string,
  onOptionChange: PropTypes.string,
  selCountry: PropTypes.string,
  renderDropdownList: PropTypes.string,
  direction: PropTypes.string,
  countryToFlag: PropTypes.string,
  label: PropTypes.string,
  textFieldSize: PropTypes.string,
  error: PropTypes.string,
  displayError: PropTypes.string,
}
